import {
  Edit,
  EventAvailable,
  Hail,
  InsertInvitation,
  ManageAccounts,
  MapsHomeWork,
  RemoveRedEye,
  SportsKabaddi,
} from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { Federation } from "../../utils/schemas/federations";
import Button from "../button/Button";
import "./federation-card.scss";

type FederationCardProps = {
  federation: Federation;
};

const FederationCard = ({ federation }: FederationCardProps) => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.auth);
  const federationDetails = [
    {
      name: "Clubs",
      data: federation.clubs,
      icon: <MapsHomeWork className="federationcard-icon" />,
      link: `/clubs?federation=${federation.id}`,
    },
    {
      name: "Deportistas",
      data: federation.deportistas,
      icon: <SportsKabaddi className="federationcard-icon" />,
      link: `/gestor_usuarios?federation=${federation.id}`,
    },
    {
      name: "Entrenadores",
      data: federation.entrenadores,
      icon: <Hail className="federationcard-icon" />,
      link: `/gestor_usuarios?federation=${federation.id}`,
    },
    {
      name: "Eventos",
      data: federation.eventos,
      icon: <InsertInvitation className="federationcard-icon" />,
      link: `/gestor_eventos?federation=${federation.id}`,
    },
    {
      name: "Eventos pendientes de validar",
      data: federation.eventosPendientes,
      icon: <EventAvailable className="federationcard-icon" />,
      link: `/gestor_eventos?federation=${federation.id}&pending=true`,
    },
  ];

  const handlePersonalButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/gestor_admins?federation=${federation.id}`);
  };
  const handleEditButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/federaciones/${federation.id}`);
  };

  const handleSeeFederation = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/federacion/${federation.id}`);
  };

  const canEditFederation = () => {
    if (userInfo?.roles.includes(ROLES.SUPER_ADMIN)) return true;
    const roles = userInfo?.roles.includes(ROLES.FEDERATION || ROLES.REFEJYDA);
    const isFederation = userInfo?.federationId === federation.id;
    return roles || isFederation;
  };

  const handleNavigate = (link: string) => {
    navigate(link);
  };

  return (
    <Paper elevation={4} className="federationcard-container">
      <Box className="federationcard-info-container">
        <Typography className="federationcard-name">{federation.name}</Typography>
        <Stack className="federationcard-info-details-container">
          <Typography className="federationcard-info-details">
            Responsable Legal: {federation.responsibleName}
          </Typography>
          <Typography className="federationcard-info-details">Email: {federation.email}</Typography>
          <Typography className="federationcard-info-details">
            Ciudad: {federation.cityName}
          </Typography>
        </Stack>
      </Box>
      <Box className="federationcard-details-container">
        {federationDetails.map((federation) => {
          return (
            <Box
              key={federation.name}
              className="federationcard-detail-container"
              onClick={() => handleNavigate(federation.link)}
            >
              <Box className="federationcard-icon-container">{federation.icon}</Box>
              <Box className="federationcard-data-container">
                <Typography className="federationcard-data value">{federation.data}</Typography>
                <Typography className="federationcard-data">{federation.name}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className="federationcard-buttons-container">
        <Button
          className="federationcard-buttons"
          rounded="md"
          startIcon={<ManageAccounts />}
          onClick={handlePersonalButtonClick}
        >
          Personal ({federation.admins ?? 0})
        </Button>
        {canEditFederation() && (
          <Button
            className="federationcard-buttons"
            rounded="md"
            startIcon={<Edit />}
            onClick={handleEditButtonClick}
          >
            Editar
          </Button>
        )}
        <Button
          className="federationcard-buttons"
          rounded="md"
          startIcon={<RemoveRedEye />}
          onClick={handleSeeFederation}
        >
          Ver
        </Button>
      </Box>
    </Paper>
  );
};

export default FederationCard;
