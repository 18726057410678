import "./clubs.scss";

import { Link, useLocation } from "react-router-dom";

import { Add, Search } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, TablePagination, Typography } from "@mui/material";

import { useEffect } from "react";
import Button from "../../components/button/Button";
import ClubCard from "../../components/clubCard/ClubCard";
import Loading from "../../components/loader/Loading";
import SelectInput from "../../components/selectInput/SelectInput";
import TextInput from "../../components/textInput/TextInput";
import { resetFilters, setFilter } from "../../context/filters/filterSlice";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import useDebounce from "../../utils/hooks/useDebouncing";
import useQueryClubs from "../../utils/hooks/useQueryClubs";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { FilterState } from "../../utils/schemas/listFilters";
import { ROLES } from "../../constants/constants";

const Clubs = () => {
  useSetPageName([{ name: "Gestor de Clubs", path: "/clubs" }]);
  const location = useLocation();

  const { autonomies, provinces } = useAppSelector((state) => state.formsOptions);
  const { userInfo } = useAppSelector((state) => state.auth);

  //Filtros
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };
  //Limpiamos los filtros
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  //Optimización de la búsqueda
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  const { data: clubs, isLoading, isRefetching } = useQueryClubs(filterState);

  const filteredProvinces = provinces?.filter(
    (province) =>
      province.autonomy_code ===
      autonomies?.find((autonomy) => autonomy.name === filterState.autonomy)?.autonomy_code
  );

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const federation = searchParams.get("federation");
      if (federation) {
        handleFilterChange(federation, "federationId");
      }
    }
  }, [location.search]);

  return (
    <Container className="clubs-container">
      <Box className="clubs-header">
        <Box className="clubs-header-searchInput-container">
          <IconButton>
            <Search className="clubs-header-searchInput-icon" />
          </IconButton>
          <TextInput
            placeholder="Buscar"
            minWidth="full-width"
            onChange={(e) => debounceSearch(e.target.value)}
          />
        </Box>
        <Box className="clubs-header-actions-container">
          <Box className="clubs-filters">
            <SelectInput
              defaultValue=""
              rounded="md"
              title="Autonomía: Todas"
              options={[
                { value: "", label: "Autonomía: Todas" },
                ...(autonomies && autonomies.length > 0
                  ? autonomies.map((autonomy) => ({
                      value: autonomy.name,
                      label: autonomy.name,
                    }))
                  : []),
              ]}
              onChange={(e) => handleFilterChange(e.target.value, "autonomy")}
              value={filterState.autonomy}
            />
            <SelectInput
              defaultValue=""
              rounded="md"
              title="Provincia: Todas"
              options={[
                { value: "", label: "Provincia: Todas" },
                ...(filteredProvinces && filteredProvinces.length > 0
                  ? filteredProvinces.map((province) => ({
                      value: province.name,
                      label: province.name,
                    }))
                  : []),
              ]}
              onChange={(e) => handleFilterChange(e.target.value, "province")}
              value={filterState.province}
            />
            {userInfo?.roles.includes(ROLES.SUPER_ADMIN || ROLES.FEDERATION) && (
              <Link to="/clubs/agregar" className="clubs-header-actions-button">
                <Button
                  startIcon={<Add />}
                  rounded="md"
                  width="fit-content"
                  className="clubs-header-actions-button"
                >
                  AGREGAR CLUB
                </Button>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      {isLoading || isRefetching ? (
        <Loading />
      ) : clubs && clubs.total > 0 ? (
        <>
          <Grid container className="pagination-container">
            <Typography className="clubs-count">Clubs: {clubs.total || 0}</Typography>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={clubs.total}
              rowsPerPage={clubs.perPage}
              page={clubs.page - 1}
              onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
              onRowsPerPageChange={(e) => {
                handleFilterChange(e.target.value, "perPage");
                handleFilterChange(1, "page");
              }}
              labelRowsPerPage="Clubs por página"
            />
          </Grid>
          <Box className="clubs-list">
            {clubs.data.map((club, index) => {
              return <ClubCard key={club.id + "-" + index} club={club} />;
            })}
          </Box>
        </>
      ) : (
        <Typography>No hay clubs disponibles</Typography>
      )}
    </Container>
  );
};

export default Clubs;
