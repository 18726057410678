import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GENRES } from "../../../constants/constants";
import { TQueryEvent } from "../../../utils/schemas/event";

type Props = {
  stocks: TQueryEvent["stocks"];
};

const StocksTable = ({ stocks }: Props) => {
  const renderTableForGenre = (genre: string) => {
    const filteredStocks = stocks.filter((stock) => stock.genre === genre);

    //Check if a genre has stock
    const hasStock = filteredStocks.some((stock) => stock.stock > 0);
    if (!hasStock) {
      return null;
    }

    return (
      <div key={genre}>
        <Typography className="eventcard-info">
          {genre === GENRES.MEN ? "Masculino: " : genre === GENRES.WOMEN ? "Femenino: " : "Mixto: "}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: 0.5 }}>Peso</TableCell>
                {filteredStocks.map((stock, index) => (
                  <TableCell key={index} sx={{ padding: 0.5 }}>
                    {stock.weight?.split(" ")[0]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: 0.5 }}>Inscritos</TableCell>
                {filteredStocks.map((stock, index) => (
                  <TableCell key={index} sx={{ padding: 0.5 }}>
                    {stock.approved}/{stock.stock}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: 0.5 }}>Reserva</TableCell>
                {filteredStocks.map((stock, index) => (
                  <TableCell key={index} sx={{ padding: 0.5 }}>
                    {stock.reserved}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <Grid container columnGap={4} flexWrap="wrap">
      {Object.values(GENRES).map((genre) => renderTableForGenre(genre))}
    </Grid>
  );
};

export default StocksTable;
