import { Container } from "@mui/material";
import useSetPageName from "../../utils/hooks/useSetPageName";

type Props = {};

const Home = (props: Props) => {
  // useSetPageName("Inicio");
  useSetPageName([{ name: "Inicio", path: "/" }]);
  return <Container sx={{ height: "100vh", p: 1 }}>RFEJYDA Home</Container>;
};

export default Home;
