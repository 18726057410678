export const purgeObjectToEmpty = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      purgeObjectToEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0 && !Array.isArray(obj[key])) {
        delete obj[key];
      }
    }
  });
  return obj;
};
