import {
  Checkbox,
  FormControl,
  Grid,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../button/Button";

import { GENRES, ROLES } from "../../../constants/constants";
import { setFilter } from "../../../context/filters/filterSlice";
import { createInscriptions } from "../../../context/forms/inscriptionActions";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import { parseDate } from "../../../utils/date";
import useDebounce from "../../../utils/hooks/useDebouncing";
import useQueryUsers from "../../../utils/hooks/useQueryUsers";
import { TOrganization } from "../../../utils/schemas/admins";
import { TClubList } from "../../../utils/schemas/club";
import { TEventStock } from "../../../utils/schemas/event";
import { TFederationsList } from "../../../utils/schemas/federations";
import { FilterState } from "../../../utils/schemas/listFilters";
import { TUserSimple } from "../../../utils/schemas/user";
import { toastMessageError, toastMessageSuccess } from "../../common/toastMessage";
import SelectInput from "../../selectInput/SelectInput";
import "./createInscriptions.scss";
import CreateFilters from "./filters/CreateInscriptionFilters";
import CustomDialog from "../../customDialog/CustomDialog";

type Props = {
  eventId: string;
  stockCategories: TEventStock[] | undefined | null;
  refetchInscriptions: () => void;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
  setTotalUsers: (total: number) => void;
};

const CreateInscription = ({
  eventId,
  stockCategories,
  federations,
  clubs,
  refetchInscriptions,
  setTotalUsers,
}: Props) => {
  const [openCreateInscription, setOpenCreateInscription] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usersToInscribe, setUsersToInscribe] = useState<TUserSimple[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<TOrganization>({
    id: "",
    type: "",
    categoryId: "",
  });

  const { categories, belts } = useAppSelector((state) => state.formsOptions);

  //Users to inscribe
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };

  //Clear filters
  useEffect(() => {
    return () => {
      handleFilterChange("", "search");
    };
  }, []);

  const { data: users, refetch: refetchUsers } = useQueryUsers(filterState);

  useEffect(() => {
    handleFilterChange(eventId, "eventToInscribe");
    setTotalUsers(users?.total || 0);
  }, [users]);

  //Filter categories by event stock
  const filteredCategories =
    categories?.filter((category) =>
      stockCategories?.find((stock) => stock.categoryId === category.id)
    ) || [];

  const handleSubmitInscriptions = async () => {
    setIsSubmitting(true);
    const usersIds = usersToInscribe.map((user) => ({
      id: user.id,
      categoryId: user.categoryId,
    }));
    const role = selectedOrganization.type;
    const data = {
      eventId: eventId,
      [role === ROLES.CLUB ? "clubId" : "federationId"]: selectedOrganization.id,
      registrants: usersIds,
    };
    const updateResponse = await dispatch(createInscriptions({ data, role }));
    if (updateResponse.meta.requestStatus === "fulfilled") {
      toastMessageSuccess("Inscripciones realizadas correctamente");
      refetchUsers();
      refetchInscriptions();
    } else {
      toastMessageError("Error al realizar las inscripciones");
      refetchUsers();
      refetchInscriptions();
    }
    setOpenCreateInscription(false);
    setIsSubmitting(false);
  };

  const handleCheckboxChange = (user: TUserSimple) => {
    const index = usersToInscribe.findIndex((selectedUser) => selectedUser.id === user.id);
    if (index === -1) {
      setUsersToInscribe([...usersToInscribe, user]);
    } else {
      const newUsersToInscribe = [...usersToInscribe];
      newUsersToInscribe.splice(index, 1);
      setUsersToInscribe(newUsersToInscribe);
    }
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setUsersToInscribe(users?.data || []);
    } else {
      setUsersToInscribe([]);
    }
  };

  //Search optimization
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  //Change user weight
  const handleChangeWeight = (e: SelectChangeEvent<unknown>, user: TUserSimple) => {
    const index = usersToInscribe.findIndex((selectedUser) => selectedUser.id === user.id);
    if (index !== -1) {
      const newUsersToInscribe = [...usersToInscribe];
      newUsersToInscribe[index] = {
        ...newUsersToInscribe[index],
        categoryId: String(e.target.value),
      };
      setUsersToInscribe(newUsersToInscribe);
    } else {
      const newUser = { ...user, categoryId: String(e.target.value) };
      setUsersToInscribe([...usersToInscribe, newUser]);
    }
  };

  return (
    <>
      <Grid className="inscriptions-container-create" container columnSpacing={4}>
        <CreateFilters
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          debounceSearch={debounceSearch}
          clubs={clubs}
          federations={federations}
          filterState={filterState}
          handleFilterChange={handleFilterChange}
        />
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            className="create-button"
            onClick={() => setOpenCreateInscription(true)}
            disabled={selectedOrganization.id === "" || usersToInscribe.length === 0}
          >
            Inscribir ({usersToInscribe.length})
          </Button>
        </Grid>
      </Grid>
      <Grid className="inscriptions-header">
        <Typography className="inscriptions-count">Usuarios: {users?.total}</Typography>
        {users && users.data?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={users?.total || 0}
            rowsPerPage={users?.perPage}
            page={users?.page - 1}
            onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
            onRowsPerPageChange={(e) => {
              handleFilterChange(e.target.value, "perPage");
              handleFilterChange(1, "page");
            }}
            labelRowsPerPage="Filas por página"
          />
        )}
      </Grid>
      <Paper elevation={4} className="container-inscription-list">
        <TableContainer>
          <Table>
            <TableHead className="table-head">
              <TableRow className="no-wrap">
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Fecha nacimiento</TableCell>
                <TableCell>Cinturón</TableCell>
                <TableCell>Categoría</TableCell>
                <TableCell>Sexo</TableCell>
                <TableCell>Peso</TableCell>
                <TableCell>
                  Acción
                  <Checkbox
                    className="checkbox-inscriptions"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.data?.map((user, _) => (
                <TableRow key={user.id} className="no-wrap">
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.surname}</TableCell>
                  <TableCell>{user.bornAt ? parseDate(user.bornAt) : ""}</TableCell>
                  <TableCell>{belts?.find((belt) => user.belt === belt.id)?.name ?? ""}</TableCell>
                  <TableCell>{user.categoryName}</TableCell>
                  <TableCell>
                    {user.genre === GENRES.MEN
                      ? "Masculino"
                      : user.genre === GENRES.WOMEN
                      ? "Femenino"
                      : ""}
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <SelectInput
                        value={
                          usersToInscribe.find((selectedUser) => selectedUser.id === user.id)
                            ?.categoryId ?? ""
                        }
                        defaultValue=""
                        rounded="md"
                        minWidth="sm"
                        onChange={(e) => {
                          handleChangeWeight(e, user);
                        }}
                        options={[
                          ...filteredCategories
                            .filter((category) => category.genre === user.genre)
                            .map((category) => ({
                              value: category.id,
                              label: category.weight,
                            })),
                        ]}
                      ></SelectInput>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      className="checkbox-inscriptions"
                      onChange={() => handleCheckboxChange(user)}
                      checked={usersToInscribe.some((selectedUser) => selectedUser.id === user.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomDialog
        isOpen={openCreateInscription}
        onClose={() => setOpenCreateInscription(false)}
        title="Inscribir usuarios"
        content="¿Estás seguro de que quieres inscribir a los usuarios seleccionados?"
        onAccept={handleSubmitInscriptions}
        isLoading={isSubmitting}
      />
    </>
  );
};

export default CreateInscription;
