import { createSlice } from "@reduxjs/toolkit";

import { getFormOptions } from "./formsActions";

type InitialStateType = {
  prefixPhoneNumber?: { id: string; name: string }[];
  countries?: { code: string; name: string }[];
  autonomies?: { autonomy_code: string; name: string }[];
  provinces?: { province_code: string; name: string; autonomy_code: string }[];
  municipalities?: { municipality_code: string; name: string; province_code: string }[];

  sports?: { id: string; name: string; code: string }[];
  belts?: { id: string; name: string }[];
  eventTypes?: { id: string; name: string; code: string }[];
  categories?: {
    id: string;
    categoryName: string;
    sportCode: string;
    weight: string;
    genre: string;
    groupAgeCode: string;
  }[];
  organizers?: { id: string; name: string }[];
  cups?: { id: string; name: string }[];

  roles?: { id: string; name: string }[];

  loading?: boolean;
  success?: boolean;
  error?: string | null;
};

const initialState: InitialStateType = {
  prefixPhoneNumber: [],
  countries: [],
  autonomies: [],
  provinces: [],
  municipalities: [],
  sports: [],
  roles: [],
  cups: [],

  loading: false,
  success: false,
  error: null,
};

const formsOptions = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOptions: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers(builder) {
    builder
      .addCase(getFormOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormOptions.fulfilled, (state, { payload }) => {
        state.sports = payload.sports;
        state.belts = payload.belts;
        state.categories = payload.categories;
        state.eventTypes = payload.eventTypes;
        state.roles = payload.roles;
        state.countries = payload.countries;
        state.autonomies = payload.autonomies;
        state.provinces = payload.provinces;
        state.municipalities = payload.municipalities;
        state.organizers = payload.organizers;
        state.cups = payload.cups;

        state.loading = false;
        state.success = true;
      })
      .addCase(getFormOptions.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message ?? null;
      });
  },
});

export const { setOptions } = formsOptions.actions;

export default formsOptions.reducer;
