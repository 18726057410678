import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TUser } from "../../../../utils/schemas/user";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { useEffect, useState } from "react";

type Props = {
  isDisabled?: boolean;
};

const BasicUserInfo = ({ isDisabled }: Props) => {
  const { register, formState, setValue, watch, control } = useFormContext<TUser>();
  const { errors } = formState;
  const [selectedSport, setSelectedSport] = useState<{ category: string; sport: string }>({
    category: "",
    sport: "",
  });

  const { categories, sports } = useAppSelector((state) => state.formsOptions);

  const uniqueCategories = new Set(categories?.map((category) => category.categoryName));

  const handleSetDate = (value: Date | null) => {
    setValue("bornAt", value?.toDateString() || "");
  };

  useEffect(() => {
    if (watch("category")) {
      setSelectedSport({
        ...selectedSport,
        sport: watch("category")?.categorySport ?? "",
        category: watch("category")?.name ?? "",
      });
      setValue("currentCategoryId", watch("category")?.id);
    }
  }, []);

  return (
    <Box className="form-section-container">
      <Typography className="add-user-form-title">Datos del usuario</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Nombre*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Nombre"
              error={!!errors?.name}
              {...register("name")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.name && (
              <Typography className="form-input-error">{errors?.name?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Fecha de nacimiento</Typography>
            <DatePicker
              className="input-text form-text-field"
              format="DD/MM/YYYY"
              maxDate={dayjs()}
              //minDate={dayjs()}
              value={watch("bornAt") ? dayjs(watch("bornAt")) : null}
              onChange={(value: Dayjs | null) => handleSetDate(value?.toDate() ?? null)}
              readOnly={isDisabled}
            />
            {errors?.bornAt && (
              <Typography className="form-input-error">{errors?.bornAt?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Apellidos*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Apellidos"
              error={!!errors?.surname}
              {...register("surname")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.name && (
              <Typography className="form-input-error">{errors?.surname?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">
              DNI**La obligatoriedad depende de la fecha de nacimiento
            </Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="DNI"
              error={!!errors?.identityNumber}
              {...register("identityNumber")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.identityNumber && (
              <Typography className="form-input-error">
                {errors?.identityNumber?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Nacionalidad</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Nacionalidad"
              error={!!errors?.nationality}
              {...register("nationality")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.nationality && (
              <Typography className="form-input-error">{errors?.nationality?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Deporte</Typography>
            <Select
              className="input-select form-select-field"
              //error={!!errors?.weight}
              displayEmpty
              defaultValue={""}
              value={selectedSport.sport}
              onChange={(e) => {
                setSelectedSport({ ...selectedSport, sport: e.target.value });
              }}
              slotProps={{ input: { readOnly: isDisabled } }}
            >
              <MenuItem value="">Deporte</MenuItem>
              {sports?.map((sport) => {
                return (
                  <MenuItem key={sport.code} value={sport.code}>
                    {sport.name}
                  </MenuItem>
                );
              })}
            </Select>
            {/* {errors?.weight && (
              <Typography className="form-input-error">{errors?.weight?.message}</Typography>
            )} */}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Categoría</Typography>
            <Select
              className="input-select form-select-field"
              //error={!!errors?.weight}
              displayEmpty
              defaultValue={""}
              value={selectedSport.category}
              onChange={(e) => {
                setSelectedSport({ ...selectedSport, category: e.target.value });
              }}
              slotProps={{ input: { readOnly: isDisabled } }}
            >
              <MenuItem value="">Categoría</MenuItem>
              {Array.from(uniqueCategories)?.map((category) => {
                return (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                );
              })}
            </Select>
            {/* {errors?.weight && (
              <Typography className="form-input-error">{errors?.weight?.message}</Typography>
            )} */}
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={6} rowSpacing={2}>
          <Grid item xs={6}>
            <FormControl>
              <Typography className="from-input-label">Género</Typography>
              <RadioGroup
                name="radio-buttons-group"
                row
                value={watch("genre") ?? ""}
                defaultValue=""
                onChange={(e) => setValue("genre", e.target.value)}
              >
                <FormControlLabel
                  value="w"
                  control={<Radio className="check-radio-orange" />}
                  label="Mujer"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="m"
                  control={<Radio className="check-radio-orange" />}
                  label="Hombre"
                  disabled={isDisabled}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Peso</Typography>
              <Controller
                name="currentCategoryId"
                control={control}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.currentCategoryId}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value="">Peso</MenuItem>
                    {categories
                      ?.filter(
                        (category) =>
                          category.genre === watch("genre") &&
                          category.sportCode === selectedSport.sport &&
                          category.categoryName === selectedSport.category
                      )
                      .map((category) => {
                        return (
                          <MenuItem key={category.id} value={category.id}>
                            {category.weight}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              />
              {errors?.category && (
                <Typography className="form-input-error">{errors?.category?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicUserInfo;
