import { Box, ListSubheader, MenuItem, Select } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { CATEGORY_NAMES, GENRES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { TRankingFilters } from "../../utils/schemas/rankings";

type Props = {
  selectedCategory: string | null;
  handleChangeCategory: (id: string) => void;
  rankingFilters: TRankingFilters;
  handleFilterChange: (
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => void;
};

const RankingFilters = ({
  selectedCategory,
  handleChangeCategory,
  rankingFilters,
  handleFilterChange,
}: Props) => {
  const { categories, sports } = useAppSelector((state) => state.formsOptions);

  const getNumericWeight = (weight: string) => {
    const numericPart = weight.match(/[\d.]+/);
    return numericPart ? parseFloat(numericPart[0]) : 0;
  };

  return (
    <Box className="ranking-table-top-filters">
      <Select
        className="input-select ranking-select-field"
        defaultValue={""}
        value={rankingFilters["sportCode"]}
        onChange={(e) => handleFilterChange("sportCode", e.target.value)}
        fullWidth
        displayEmpty
      >
        <MenuItem value="">Deporte: Todos</MenuItem>
        {sports?.map((sport) => (
          <MenuItem value={sport.code} key={sport.id}>
            {sport.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        className="input-select ranking-select-field"
        defaultValue={""}
        value={rankingFilters["category"]}
        onChange={(e) => handleFilterChange("category", e.target.value)}
        fullWidth
        displayEmpty
      >
        <MenuItem value="">Categoría: Todas</MenuItem>
        {CATEGORY_NAMES?.map((sport) => (
          <MenuItem value={sport} key={sport}>
            {sport}
          </MenuItem>
        ))}
      </Select>
      <Select
        className="input-select ranking-select-field"
        defaultValue=""
        value={selectedCategory}
        fullWidth
        displayEmpty
        onChange={(e) => handleChangeCategory(e.target.value ?? "")}
      >
        <MenuItem value="">Peso: Todos</MenuItem>
        <ListSubheader>Hombre</ListSubheader>
        {categories
          ?.filter(
            (category) =>
              category.genre === GENRES.MEN &&
              category.sportCode === rankingFilters.sportCode &&
              category.categoryName === rankingFilters.category
          )
          .sort((a, b) => getNumericWeight(a.weight) - getNumericWeight(b.weight))
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              {category.weight}
            </MenuItem>
          ))}
        <ListSubheader>Mujer</ListSubheader>
        {categories
          ?.filter(
            (category) =>
              category.genre === GENRES.WOMEN &&
              category.sportCode === rankingFilters.sportCode &&
              category.categoryName === rankingFilters.category
          )
          .sort((a, b) => getNumericWeight(a.weight) - getNumericWeight(b.weight))
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              {category.weight}
            </MenuItem>
          ))}
      </Select>
      <DesktopDatePicker
        value={rankingFilters["date"] ? dayjs(rankingFilters["date"]) : null}
        format="DD/MM/YYYY"
        className="input-select ranking-select-field ranking-date-picker"
        onChange={(value) => handleFilterChange("date", value?.toISOString())}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => handleFilterChange("date", ""),
          },
        }}
        disableFuture
      />
    </Box>
  );
};

export default RankingFilters;
