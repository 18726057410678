import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Add, Delete } from "@mui/icons-material";
import { Box, FormControl, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";

import { TClub } from "../../../../utils/schemas/club";
import Button from "../../../button/Button";

type Props = {
  isDisabled?: boolean;
};

const ResponsableUser = ({ isDisabled }: Props) => {
  const { register, formState, setValue, clearErrors, watch } = useFormContext<TClub>();
  const users = watch("users");
  const { errors } = formState;
  const [formUsers, setUsers] = useState<TClub["users"]>(
    users ?? [
      {
        name: "",
        surname: "",
        email: "",
        type: "",
      },
    ]
  );

  const handleAddUser = () => {
    setUsers((prev) => [
      ...(prev || []),
      {
        name: "",
        surname: "",
        email: "",
        roleId: "",
      },
    ]);
  };

  const handleRemoveUser = (index: number) => {
    setUsers((prev) => [...(prev || [])].filter((_, i) => i !== index));
  };

  const handleOnChange = (index: number, key: "name" | "surname" | "email", value: string) => {
    setUsers((prev) => {
      const formUsers = [...(prev || [])];
      formUsers[index][key] = value;
      return formUsers;
    });
    clearErrors(`users.${index}.${key}`);
    setValue(`users.${index}.${key}`, value);
  };

  useEffect(() => {
    const users = watch("users");
    setUsers(users);
  }, []);

  return (
    <Box className="form-section-container">
      <Stack gap={5} mb={4}>
        {formUsers?.map((user, i) => {
          return (
            <Box key={i}>
              <Typography className="form-section-title" sx={{ marginBottom: 2 }}>
                Usuario responsable
              </Typography>
              <Grid container columnSpacing={4} rowSpacing={2} alignItems="center">
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Nombre</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Nombre*"
                      value={user.name}
                      error={!!errors?.users?.[i]?.name}
                      {...register(`users.${i}.name`)}
                      onChange={(e) => handleOnChange(i, "name", e.target.value)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {errors?.users?.[i]?.name && (
                      <Typography className="form-input-error">
                        {errors?.users?.[i]?.name?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Apellidos</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Apellidos*"
                      value={user.surname}
                      error={!!errors?.users?.[i]?.surname}
                      {...register(`users.${i}.surname`)}
                      onChange={(e) => handleOnChange(i, "surname", e.target.value)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {errors?.users?.[i]?.surname && (
                      <Typography className="form-input-error">
                        {errors?.users?.[i]?.surname?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Email</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Email*"
                      value={user.email}
                      error={!!errors?.users?.[i]?.email}
                      {...register(`users.${i}.email`)}
                      onChange={(e) => handleOnChange(i, "email", e.target.value)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {errors?.users?.[i]?.email && (
                      <Typography className="form-input-error">
                        {errors?.users?.[i]?.email?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={0.5} xs={12} container alignContent="center" justifyContent="center">
                  <IconButton onClick={() => handleRemoveUser(i)} disabled={isDisabled}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="center" gap={5}>
        <Button
          width="fit-content"
          className="form-button small-text"
          startIcon={<Add />}
          onClick={() => handleAddUser()}
          disabled={isDisabled}
        >
          AGREGAR NUEVO USUARIO
        </Button>
      </Stack>
    </Box>
  );
};

export default ResponsableUser;
