import { Controller, useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";

import { useAppSelector } from "../../../../context/hooks/hooks";
import { TEvent } from "../../../../utils/schemas/event";

import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import Categories from "../categories/Categories";
import "./event-type.scss";

type Props = {
  isDisabled?: boolean;
};

const EventType = ({ isDisabled }: Props) => {
  const { formState, watch, control, setValue } = useFormContext<TEvent>();
  const { errors } = formState;

  const { categories, eventTypes, sports } = useAppSelector((state) => state.formsOptions);
  const selectedSportName = watch("sportCode");
  const selectedCategory = watch("categoryCode");

  // Filtrar categorías por deporte
  const uniqueCategories = new Set();
  const filteredCategories = categories
    ?.filter((category) => category.sportCode === selectedSportName)
    .filter((category) => {
      if (uniqueCategories.has(category.categoryName)) {
        return false;
      } else {
        uniqueCategories.add(category.categoryName);
        return true;
      }
    });

  //Update date
  const handleSetDate = (value: Date | null) => {
    setValue("weighingChangeLimitDate", value ? value : "");
  };

  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Tipo de evento</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        {/* ELEGIR DEPORTE */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Deporte*</Typography>
            <Controller
              name="sportCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  {...field}
                  className="input-select form-select-field"
                  error={!!errors.sportCode}
                  defaultValue={""}
                  displayEmpty
                  slotProps={{
                    input: { readOnly: isDisabled },
                  }}
                >
                  <MenuItem value={""}>
                    <em>Deporte</em>
                  </MenuItem>
                  {sports?.map((sport) => {
                    return (
                      <MenuItem key={sport.id} value={sport.code}>
                        {sport.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            {errors?.sportCode && (
              <Typography className="form-input-error">{errors?.sportCode?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        {/* Categoría DE EVENTO */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Categoría*</Typography>
            <Controller
              name="categoryCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  {...field}
                  className="input-select form-select-field"
                  error={!!errors?.categoryCode}
                  displayEmpty
                  defaultValue={""}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("stocks", []);
                  }}
                  slotProps={{
                    input: { readOnly: isDisabled },
                  }}
                >
                  <MenuItem value={""}>
                    <em>Categoría</em>
                  </MenuItem>
                  {filteredCategories
                    ?.filter((category) => category.sportCode === selectedSportName)
                    .map((category) => {
                      return (
                        <MenuItem key={category.id} value={category.categoryName}>
                          {category.categoryName}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
            />
            {errors?.eventType && (
              <Typography className="form-input-error">{errors?.eventType?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        {/* TIPO DE EVENTO */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Tipo de evento*</Typography>
            <Controller
              name="eventType"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  {...field}
                  className="input-select form-select-field"
                  error={!!errors?.eventType}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{
                    input: { readOnly: isDisabled },
                  }}
                >
                  <MenuItem value={""}>
                    <em>Tipo de evento</em>
                  </MenuItem>
                  {eventTypes?.map((eventType) => {
                    return (
                      <MenuItem key={eventType.id} value={eventType.code}>
                        {eventType.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            {errors?.eventType && (
              <Typography className="form-input-error">{errors?.eventType?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} className="teams-check-container">
          <FormControl fullWidth>
            <Typography className="form-input-label">Fecha limite cambio de categoría*</Typography>
            <DatePicker
              className="input-text form-text-field"
              format="DD/MM/YYYY"
              minDate={dayjs()}
              value={
                watch("weighingChangeLimitDate") ? dayjs(watch("weighingChangeLimitDate")) : null
              }
              onChange={(value: Dayjs | null) => handleSetDate(value?.toDate() ?? null)}
              readOnly={isDisabled}
            />
            {errors?.weighingChangeLimitDate && (
              <Typography className="form-input-error">
                {errors?.weighingChangeLimitDate?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* Equipos  FASE 2*/}
        {/* <Grid item md={6} xs={12} className="teams-check-container">
          <FormControlLabel
            className="form-checkbox-container"
            control={
              <Checkbox
                className="form-checkbox"
                disabled={isDisabled}
                value={watch("isByTeams") || watch("isByTeams") === 1}
                onChange={(e) => setValue("isByTeams", e.target.checked ? true : false)}
              />
            }
            label="Por equipos"
          />
        </Grid> */}
      </Grid>
      <Categories
        selectedCategory={selectedCategory}
        selectedSportName={selectedSportName}
        isDisabled={isDisabled}
      />
    </Box>
  );
};

export default EventType;
