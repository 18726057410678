import "./addUser.scss";
import "../../../components/formComponents/form-components.scss";

import { useEffect, useState } from "react";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LooksOne, LooksTwo } from "@mui/icons-material";
import { Box, Container, Paper, Stack, Step, StepLabel, Stepper } from "@mui/material";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { TUser, addUserSchema } from "../../../utils/schemas/user";
import BasicUserInfo from "../../../components/formComponents/createUser/basicInformation/BasicInformation";
import UserContactInformation from "../../../components/formComponents/createUser/contactInformation/ContactInformation";
import MinorRepresentatives from "../../../components/formComponents/createUser/minorRepresentatives/MinorRepresentatives";
import Button from "../../../components/button/Button";
import SportData from "../../../components/formComponents/createUser/sportData/SportData";
import { useAppDispatch } from "../../../context/hooks/hooks";
import { sendUserData, updateUserData } from "../../../context/forms/formsActions";
import useQueryUserById from "../../../utils/hooks/useQueryUserById";
import { zodResolver } from "@hookform/resolvers/zod";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import Loading from "../../../components/loader/Loading";

const steps = ["Información general", "Datos deportivos"];

type Props = {
  isDisabled?: boolean;
};

const AddEditUser = ({ isDisabled }: Props) => {
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  useSetPageName([
    { name: "Gestor de Usuarios", path: "/gestor_usuarios" },
    {
      name: isDisabled ? "Ver Usuario" : isEdit ? "Editar Usuario" : "Crear usuario",
      path: "/users",
    },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formMethods = useForm<TUser>({ mode: "all", resolver: zodResolver(addUserSchema) });
  //Logic to know if is edit
  const { id: userId } = useParams();
  const { userData, activate } = useQueryUserById(userId);

  //Validations
  const validateStep1 = async () =>
    await formMethods.trigger([
      "name",
      "bornAt",
      "surname",
      "identityNumber",
      "nationality",
      "currentCategoryId",
      "genre",
      "address",
      "phone",
      "email",
      "responsibles",
    ]);

  const validateStep2 = async () =>
    await formMethods.trigger([
      "federationId",
      "clubId",
      "currentBeltId",
      "cnr",
      "coachType",
      "refereeType",
      "avatarUrl",
      "allergy",
      "profession",
    ]);

  useEffect(() => {
    if (userId) {
      setIsEdit(true);
      activate();
    }
  }, [userId]);

  useEffect(() => {
    if (userData.data) {
      formMethods.reset(userData.data);
    }
  }, [userData.data]);

  const CustomStepIcon = ({
    active,
    completed,
    icon,
  }: {
    active: boolean;
    completed: boolean;
    icon: number;
  }) => {
    const stepIcons = [
      <LooksOne className="stepper-icon" />,
      <LooksTwo className="stepper-icon" />,
    ];

    return (
      <div className={`stepper-step ${active ? "active" : ""} ${completed ? "completed" : ""}`}>
        {stepIcons[icon - 1]}
      </div>
    );
  };

  const handleBackButton = () => {
    if (activeStep === 0) {
      navigate("/gestor_usuarios");
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep - 1);
    }
  };
  const handleStep = (step: number) => async () => {
    if (step === 1) {
      if (!(await validateStep1())) {
        return;
      }
    } else if (activeStep === 0) {
      if (!(await validateStep2())) {
        return;
      }
    }
    setActiveStep(step);
  };
  const totalSteps = () => {
    return steps.length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNextButton = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    //Comprobamos que los campos de la parte actual del form están correctos
    if (activeStep === 0) {
      if (!(await validateStep1())) {
        return;
      }
    } else if (activeStep === 1) {
      if (!(await validateStep2())) {
        return;
      }
    }
    setActiveStep(activeStep + 1);
  };

  const onSubmit: SubmitHandler<TUser> = async (data) => {
    formMethods.getValues();
    if (isEdit && userId) {
      const updateResponse = await dispatch(updateUserData({ data: data, id: userId }));
      if (updateResponse.type.includes("fulfilled")) {
        toastMessageSuccess("Usuario editado correctamente");
        setTimeout(() => {
          navigate("/gestor_usuarios");
        }, 2000);
      } else {
        toastMessageError(updateResponse.payload ?? "Error al editar usuario");
      }
    } else {
      const createResponse = await dispatch(sendUserData({ data }));
      if (createResponse.type.includes("fulfilled")) {
        toastMessageSuccess("Usuario creado correctamente");
        setTimeout(() => {
          navigate("/gestor_usuarios");
        }, 2000);
      } else {
        toastMessageError(createResponse.payload ?? "Error al crear usuario");
      }
    }
  };

  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("❌Errores form usurio", error);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clubId = searchParams.get("clubId");
    if (clubId) {
      formMethods.setValue("clubId", clubId);
    }
  }, [location]);

  if ((userData.isLoading && isEdit) || (isEdit && userData.isRefetching)) {
    return <Loading />;
  }

  return (
    <Container sx={{ py: { md: 5, xs: 1 } }} maxWidth={false}>
      <Box className="stepper-container">
        <Stepper nonLinear activeStep={activeStep} connector={null}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel
                className="stepper-step-container"
                onClick={handleStep(index)}
                StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit, onError)} className="form-container">
          <Paper className="form-paper" sx={{ p: { md: 5, xs: 1 }, mb: 5 }}>
            {/* Step 1 */}
            <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
              <BasicUserInfo isDisabled={isDisabled} />
              <UserContactInformation isDisabled={isDisabled} />
              <MinorRepresentatives isDisabled={isDisabled} />
            </Box>
            {/* Step 2 */}
            <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
              <SportData isDisabled={isDisabled} />
            </Box>
          </Paper>
          <Stack direction="row" justifyContent="center" gap={5} flexWrap="wrap">
            <Button className="form-button cancel" onClick={handleBackButton}>
              {activeStep === 0 ? "CANCELAR" : "ANTERIOR"}
            </Button>
            {isLastStep() ? (
              <Button type="submit" className="form-button" disabled={isDisabled}>
                ENVIAR
              </Button>
            ) : (
              <Button className="form-button" onClick={(event) => handleNextButton(event)}>
                SIGUIENTE
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
};

export default AddEditUser;
