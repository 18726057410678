import "../../../components/formComponents/form-components.scss";
import "./add-club.scss";

import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import { Looks3, LooksOne, LooksTwo } from "@mui/icons-material";
import { Box, Container, Paper, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";

import Button from "../../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import BillingData from "../../../components/formComponents/createClub/billingData/BillingData";
import BoardMembers from "../../../components/formComponents/createClub/boardMembers/BoardMembers";
import ClubData from "../../../components/formComponents/createClub/clubData/ClubData";
import ContactInfo from "../../../components/formComponents/createClub/contactInfo/ContactInfo";
import Direction from "../../../components/formComponents/createClub/direction/Direction";
import ResponsableUser from "../../../components/formComponents/createClub/responsableUser/ResponsableUser";
import Schedule from "../../../components/formComponents/createClub/schedule/Schedule";
import SocialMedia from "../../../components/formComponents/createClub/socialMedia/SocialMedia";
import { ROLES } from "../../../constants/constants";
import { sendClubData, updateClubData } from "../../../context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import useQueryClub from "../../../utils/hooks/useQueryClub";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import { TClub, addClubSchema } from "../../../utils/schemas/club";
import Loading from "../../../components/loader/Loading";
import ClubMap from "../../../components/formComponents/createClub/maps/ClubMap";

type Props = {
  isDisabled?: boolean;
};
const steps = ["Información general", "Detalles", "Accesos"];

const CreateEditClub = ({ isDisabled }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const { roles } = useAppSelector((state) => state.formsOptions);

  useSetPageName([
    { name: "Gestor de Clubes", path: "/clubs" },
    {
      name: isDisabled ? "Ver club" : isEdit ? "Editar club" : "Agregar nuevo club",
      path: "/clubes/agregar",
    },
  ]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formMethods = useForm<TClub>({
    mode: "all",
    resolver: zodResolver(addClubSchema),
  });
  const formErrors = formMethods.formState.errors;

  //editar club
  const { clubId = "" } = useParams();
  const { clubQuery, activate } = useQueryClub(clubId);
  const { isLoading, isRefetching } = clubQuery;

  useEffect(() => {
    if (isEdit && clubQuery.data) {
      formMethods.reset(clubQuery.data);
    }
  }, [clubQuery.data]);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const onSubmit: SubmitHandler<TClub> = async (data) => {
    setDisabledButton(true);
    if (isEdit) {
      const updateResponse = await dispatch(updateClubData({ data: data, id: clubId }));
      if (updateResponse.meta.requestStatus === "fulfilled") {
        toastMessageSuccess("Club actualizado correctamente");
        setTimeout(() => {
          navigate("/clubs");
        }, 2000);
      } else {
        setDisabledButton(false);
        toastMessageError(updateResponse.payload ?? "Error al actualizar club");
      }
    } else {
      if (roles && data.users) {
        data.users.forEach((user, _) => {
          user.type = roles.find((role) => role.name === ROLES.CLUB)?.id || "";
        });
      }
      const sendResponse = await dispatch(sendClubData(data));
      if (sendResponse.meta.requestStatus === "fulfilled") {
        toastMessageSuccess("Club creado correctamente");
        setTimeout(() => {
          navigate("/clubs");
        }, 2000);
      } else {
        toastMessageError(sendResponse.payload ?? "Error al crear club");
        setDisabledButton(false);
      }
    }
  };

  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("Errores AddClub", error);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const validateStep1 = async () =>
    await formMethods.trigger([
      "name",
      "shortCode",
      "description",
      "email",
      "phone",
      "webSite",
      "address",
      "fiscalAddress",
      "taxName",
      "iban",
      "cif",
      "logoUrl",
    ]);

  const validateStep2 = async () =>
    await formMethods.trigger(["weekSchedule", "boardMembers", "socialMedia"]);
  const validateStep3 = async () => await formMethods.trigger(["users"]);

  const handleStep = (step: number) => async () => {
    setActiveStep(step);
  };

  const CustomStepIcon = ({
    active,
    completed,
    icon,
  }: {
    active: boolean;
    completed: boolean;
    icon: number;
  }) => {
    const stepIcons = [
      <LooksOne className="stepper-icon" />,
      <LooksTwo className="stepper-icon" />,
      <Looks3 className="stepper-icon" />,
    ];

    return (
      <div className={`stepper-step ${active ? "active" : ""} ${completed ? "completed" : ""}`}>
        {stepIcons[icon - 1]}
      </div>
    );
  };

  const handleBackButton = () => {
    if (activeStep === 0) {
      navigate("/clubs");
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextButton = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (!(await validateStep1())) {
        return;
      }
    } else if (activeStep === 1) {
      if (!(await validateStep2())) {
        return;
      }
    } else if (activeStep === 2) {
      if (!(await validateStep3())) {
        return;
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setActiveStep(activeStep + 1);
  };

  //Check de si es edición
  useEffect(() => {
    if (clubId && clubId !== "") {
      setIsEdit(true);
      activate();
    }
  }, [clubId]);

  if (isEdit && (isLoading || isRefetching)) {
    return <Loading />;
  }
  return (
    <Container sx={{ py: { md: 5, xs: 1 } }} maxWidth={false}>
      <Box className="stepper-container">
        <Stepper nonLinear activeStep={activeStep} connector={null}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel
                className="stepper-step-container"
                onClick={handleStep(index)}
                StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit, onError)} className="form-container">
          <Paper className="form-paper" sx={{ p: { md: 5, xs: 1 }, mb: 5 }}>
            {/* Step 1 */}
            <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
              <ClubData isDisabled={isDisabled} />
              <ContactInfo isDisabled={isDisabled} />
              <Direction fiscal isDisabled={isDisabled} />
              <BillingData isDisabled={isDisabled} />
            </Box>
            {/* Step 2 */}
            <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
              <Schedule isDisabled={isDisabled} />
              <BoardMembers isDisabled={isDisabled} />
              <ClubMap isDisabled={isDisabled} />
              <SocialMedia isDisabled={isDisabled} />
            </Box>
            {/* Step 3 */}
            <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
              <ResponsableUser isDisabled={isDisabled} />
            </Box>
          </Paper>
          {formErrors && Object.keys(formErrors).length > 0 ? (
            <Typography className="error-message">*Revise los campos del formulario</Typography>
          ) : null}
          <Stack direction="row" justifyContent="center" gap={5} flexWrap="wrap">
            <Button className="form-button cancel" onClick={handleBackButton}>
              {activeStep === 0 ? "CANCELAR" : "ANTERIOR"}
            </Button>
            {isLastStep() ? (
              <Button type="submit" className="form-button" disabled={disabledButton || isDisabled}>
                {isEdit ? "GUARDAR" : "CREAR"}
              </Button>
            ) : (
              <Button className="form-button" onClick={handleNextButton}>
                SIGUIENTE
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
};

export default CreateEditClub;
