export const ROLES = {
  CLUB: "club",
  FEDERATION: "federation",
  REFEJYDA_IT: "rfejyda-it",
  REFEJYDA: "rfejyda",
  SUPER_ADMIN: "super-admin",
};

export const ROLES_OPTIONS = [
  { value: "", label: "Rol: Todos" },
  { value: ROLES.CLUB, label: "Club" },
  { value: ROLES.FEDERATION, label: "Federación" },
  { value: ROLES.REFEJYDA_IT, label: "RFEJYDA IT" },
  { value: ROLES.REFEJYDA, label: "RFEJYDA" },
];

export const ADMIN_ROLES = [
  { value: ROLES.CLUB, label: "Club" },
  { value: ROLES.FEDERATION, label: "Federación" },
  { value: ROLES.REFEJYDA_IT, label: "RFEJYDA IT" },
];

export const INSCRIPTIONS_ROLES = [ROLES.CLUB, ROLES.FEDERATION];

export const CATEGORY_NAMES = [
  "absoluto",
  "alevin",
  "benjamin",
  "cadete",
  "chupetin",
  "infantil",
  "junior",
  "master",
  "mini-benjamin",
  "veteranos",
];

export const GROUP_AGE_CODE = [
  "mas-21",
  "mas-30",
  "mas-45",
  "sub-11",
  "sub-14",
  "sub-16",
  "sub-18",
  "sub-21",
  "sub-6",
  "sub-9",
];

export const GENRES = {
  WOMEN: "w",
  MEN: "m",
  MIXTO: "mixto",
};

export const GENRES_OPTIONS = [
  { value: "", label: "Genero: Todos" },
  { value: GENRES.WOMEN, label: "Femenino" },
  { value: GENRES.MEN, label: "Masculino" },
];

export const ORGANIZATOR_TYPES = ["club", "federation", "externo"];

export const INSCRIPTION_STATUS = {
  PENDING: "pending",
  REJECTED: "rejected",
  RESERVED: "reserved",
  APPROVED: "approved",
  APPROVED_OR_RESERVED: "approved-or-reserved", // This one only for the filter
};

export const EVENT_STATUS = [
  { label: "Pendiente", value: "pending" },
  { label: "Validado", value: "validated" },
  { label: "Rechazado", value: "decline" },
];
